import Gallery from '@browniebroke/gatsby-image-gallery'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import * as styles from '../../assets/css/plan.module.css'
import Layout from '../../components/Layout'

/* childImageSharp thumb width should be in sync with css class planContent grid-template-columns: .5fr 2fr 150px */
const essayQuery = graphql`
  query {
    content(uid: { eq: "c7" }) {
      headline
      items {
        content
        images {
          childImageSharp {
            full: gatsbyImageData(layout: FULL_WIDTH)
            thumb: gatsbyImageData(height: 150, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

const ThePlan = () => {
  const { content } = useStaticQuery(essayQuery)
  const images = []
  content['items'].forEach((element) => {
    const imageData = element['images'][0].childImageSharp
    imageData.thumbAlt = element['content']
    imageData.title = element['content']
    images.push(imageData)
  })
  return (
    <Layout>
      <section>
        <h1 className='headline'>{content.headline}</h1>
            <div>
              <p>
                The plan for the town of Seaside began in 1978 after Robert
                Davis was gifted an 80 acre plot of land in the Florida
                Panhandle. Following in his grandfather&apos;s footsteps, Robert and
                his wife Daryl set out to build a &quot;livable&quot; resort
                town in the &quot;Redneck Riviera&quot; and create a haven for
                those who missed the communities that were developed when cars
                were not the dominant form of transportation. Enter Andrés Duany
                and Elizabeth Plater-Zyberk, a lauded husband and wife team from
                the prestigious architectural firm Arquitectonica. The four of
                them, along with European classicist and town planner Léon
                Krier, set out to design the kind of place that had been
                overlooked in contemporary American town planning. The kind of
                community we all wish we could be from.
              </p>
              <p>
                Planning Seaside developed over several years; first in the
                offices of Arquitectonica and later in the firm of Duany
                Plater-Zyberk and Company (DPZ). The final plan, the result of
                many drafts, was completed in about 1985 and is the result of
                the efforts of DPZ, contributions by Leon Krier, and numerous
                tests and charrettes.
              </p>
            </div>
            <div className={styles.planGallery}>
              <Gallery images={images} />
            </div>
            <div>
              <p>
                Explore the development of the plan along with commentary
                provided by Andrés Duany (found in the 'i' off to the side of
                the image name). The exploration takes you from an early aerial
                photo taken in 1965 to the most recent aerial photo taken in
                2010. Unless otherwise noted all images are provided courtesy of
                Duany Plater-Zyberk and Company.
              </p>
            </div>
      </section>
    </Layout>
  )
}

export default ThePlan
